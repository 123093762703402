<template>
  <v-container grid-list-md>
    <v-layout row>
      <v-flex
        xs12
        sm5
        md5
      >
        <prospective-student-list @selected="selected = $event" />
      </v-flex>
      <v-flex
        xs12
        sm7
        md7
      >
        <prospective-student-card
          v-if="selected"
          :student="selected"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ProspectiveStudentList from '@/components/prospective-student/ProspectiveStudentList.vue'
import ProspectiveStudentCard from '@/components/prospective-student/ProspectiveStudentCard.vue'

export default {
  name: 'RegViewProspectiveStudent',
  components: {
    'prospective-student-list': ProspectiveStudentList,
    'prospective-student-card': ProspectiveStudentCard,
  },
  data() {
    return {
      selected: null,
    }
  },
}
</script>
