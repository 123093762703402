<template>
  <v-card>
    <!-- <v-img
          :src="`http://teacademy.ca:8080/api/image/student/${ item.id }`"
          aspect-ratio="2"
          height="300px"
        > -->
    <v-layout
      column
      fill-height
    >
      <v-card-title>
        <v-spacer />
        <card-menu-button
          :item="item"
          @menuOptionClicked="$router.push($event)"
        />
      </v-card-title>

      <v-spacer />

      <v-card-title class="white--text pl-5 pt-5">
        <div class="display-1 pl-5 pt-5">
          {{ `${ capFirst(item.firstName) } ${ capFirst(item.lastName) }` }}
        </div>
      </v-card-title>
    </v-layout>
    <!-- </v-img> -->

    <v-list two-line>
      <v-list-tile>
        <v-list-tile-action>
          <v-icon color="indigo">
            phone
          </v-icon>
        </v-list-tile-action>

        <v-list-tile-content>
          <v-list-tile-title>{{ item.homephone }}</v-list-tile-title>
          <v-list-tile-sub-title>Mobile</v-list-tile-sub-title>
        </v-list-tile-content>

        <v-list-tile-action>
          <v-icon>chat</v-icon>
        </v-list-tile-action>
      </v-list-tile>

      <v-list-tile>
        <v-list-tile-action />

        <v-list-tile-content>
          <v-list-tile-title>{{ item.cellphone }}</v-list-tile-title>
          <v-list-tile-sub-title>Work</v-list-tile-sub-title>
        </v-list-tile-content>

        <v-list-tile-action>
          <v-icon>chat</v-icon>
        </v-list-tile-action>
      </v-list-tile>

      <v-divider inset />

      <v-list-tile>
        <v-list-tile-action>
          <v-icon color="indigo">
            mail
          </v-icon>
        </v-list-tile-action>

        <v-list-tile-content>
          <v-list-tile-title>{{ item.email }}</v-list-tile-title>
          <v-list-tile-sub-title>Personal</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>

      <v-divider inset />

      <v-list-tile>
        <v-list-tile-action>
          <v-icon color="indigo">
            location_on
          </v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>{{ item.address }}</v-list-tile-title>
          <v-list-tile-sub-title>
            {{ item.province }}, {{ item.postalCode }}
          </v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
      <!-- <v-divider inset></v-divider>

          <v-list-tile>
            <v-list-tile-action></v-list-tile-action>

            <v-list-tile-content>
              <v-list-tile-title>
                <v-select
                  v-model="item.studentType"
                  :items="studentTypes"
                  item-text="type"
                  item-value="typeID"
                  @change="updateApplicant()"
                  solo
                ></v-select>
                <v-spacer></v-spacer>
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile> -->
    </v-list>
  </v-card>
</template>

<script>
import ProspectiveStudentCardMoreButton from '@/components/prospective-student/ProspectiveStudentCardMoreButton.vue'

export default {
  name: 'ProspectiveStudentCard',
  components: {
    'card-menu-button': ProspectiveStudentCardMoreButton,
  },
  props: ['student'],
  data() {
    return {
      studentTypes: [],
      selectedTypeID: null,
      item: {},
    }
  },
  computed: {
    studentType() {
      return this.studentTypes.find(type => type.typeID === this.student.studentType) || {}
    },
  },
  watch: {
    student(value) {
      this.item = value
    },
  },
  beforeMount() {
    this.item = this.student
  },
  methods: {
    updateApplicant() {
      this.$store.dispatch('applicants/update', this.item)
    },
    capFirst(v) {
      return v ? v.charAt(0).toUpperCase() + v.slice(1) : ''
    },
    handleError(e) {
      const event = e
      event.target.src =
        'http://icons.iconarchive.com/icons/papirus-team/papirus-status/256/avatar-default-icon.png'
    },
    loadFile(type, id) {
      this.$store.dispatch(`applicants/${type}`, id)
        .then((data) => {
          const file = new Blob([data], { type: 'application/pdf' })
          const fileURL = URL.createObjectURL(file)
          window.open(fileURL)
        })
    },
  },
}
</script>
